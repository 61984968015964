import React, { useState, useEffect, useContext } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import DataContext from "../../utils/Context/DataContext";

let myModal : any = {};


export default function ModalCentralized(props: any) {
  const context = useContext(DataContext);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SetIdValue] = useState(useParams());
  const [product_also_centerlized,SetProductAlsoCentralized] = useState(true);

  useEffect(() => {
    if (props.open) {
      console.log("ModalCentralized On Open :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("CentralizedModal"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  useEffect(() => {
    console.log("ModalCentralized On mount :", props);
    let myModal1 : any = document.getElementById("CentralizedModal");
    myModal1.addEventListener("hidden.bs.modal", function (event : any) {
      props.close(close_data);
    });
  }, []);

  async function update_group_centralized(get_data : any) {
    let pass_data : any = {
      get: {},
      post: {
        group_id: get_data.group_id,
        status: get_data.status,
      },
    };

    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    if (product_also_centerlized) {
      pass_data.post.group_products = 1;
    } 

    let response : any = await api("/product/update_group_centralized", pass_data);
    console.log("/product/update_group_centralized response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("updated successfully");
        let item = close_data;
        item.action = "save";
        SetCloseData(item);
        myModal.hide();
      }
    }
  }

  async function update_product_centralized(get_data : any) {
    let pass_data : any = {
      get: {},
      post: {
        product_id: get_data.product_id,
        status: get_data.status,
      },
    };

    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    // if (product_also_centerlized) {
    //   pass_data.post.group_products = 1;
    // } 

    let response : any = await api("/product/update_product_centralized", pass_data);
    console.log("/product/update_product_centralized response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("updated successfully");
        let item = close_data;
        item.action = "save";
        SetCloseData(item);
        myModal.hide();
      }
    }
  }

  return (
    <div
      className="modal"
      id="CentralizedModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg  modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            {props.data.type == "centralized" ? (
              props.data.from == "product_group" ? (
                <h6>Make Product Group Centralized</h6>
              ) : (
                <h6>Make Product Centralized</h6>
              )
            ) : (
              props.data.from == "product_group" ? (
                <h6>Make Product Group Uncentralized</h6>
              ) : (
                <h6>Make Product Uncentralized</h6>
              )
            )}
            {/* <h5 className="  modal-title" id="ModalLabel">
              {props.data.from == "centralized" ? (
                "Make Uncentralized"
              ) : (
                "Make Centralized"
              )}
            </h5> */}
            {/* <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button> */}
          </div>
          {/* body */}
          <div className="modal-body">
            <div className="">
              {props.data.type == "centralized" ? (
                props.data.from == "product_group" ? (
                  <p>Are you sure you want to centralize the product group.</p>
                ) : (
                  <p>Are you sure you want to centralize the product.</p>
                )
              ) : (
                props.data.from == "product_group" ? (
                  <p>Are you sure you want to uncentralize the product group.</p>
                ) : (
                  <p>Are you sure you want to uncentralize the product.</p>
                )
              )}
            </div>
            
            {props.data.from == "product_group" && (
              <div className="modal-centralize-product-term">
                <input 
                  type="checkbox" 
                  id="centralize" 
                  checked={product_also_centerlized}
                  onChange={(e)=>{
                    SetProductAlsoCentralized(e.target.checked)
                  }}
                />
                {props.data.type == "centralized" ? (
                  <label >
                    By selecting this, you agree to centralize the product group along with all its associated products.
                  </label>
                )  :(
                  <label >
                    By selecting this, you agree to uncentralize the product group along with all its associated products.
                  </label>
                )}
                
              </div>
            )}

          </div>
          {/* footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary-outline"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                if(props.data.from == "product_group"){
                  let send_data = {
                    group_id : id_value.product_group_id,
                    status : 0
                  }
                  if(props.data.type == "centralized"){
                    send_data.status = 1
                  }else{
                    send_data.status = 2
                  }
                  update_group_centralized(send_data)
                }else{
                  let send_data = {
                    product_id : id_value.product_variant_id,
                    status : 0
                  }
                  if(props.data.type == "centralized"){
                    send_data.status = 1
                  }else{
                    send_data.status = 2
                  }
                  update_product_centralized(send_data)
                }
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
