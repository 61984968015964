import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Entypo,
  Ionicons,
  AntDesign,
} from "@expo/vector-icons";
import toast from "react-hot-toast";
import AddonModal from "./AddonModal";
import ModalMrp from "./ModalMrp";
import UpdateAddonModal from "./UpdateAddonModal";
import UpdateGroupDetailModal from "./UpdateGroupDetailModal";

import ModalCreateAddonGroup from "../AddonGroups/ModalCreateAddonGroup";
// import 'react-alice-carousel/lib/scss/alice-carousel.scss'
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";

import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import ModalAddGallery from "../Gallery/ModalAddGallery";
import ModalDeleteConfirm from "./ModalDeleteConfirm";
import { AppHelper } from "../../utils/Helper";
import ModalCentralized from "../ProductsVariant/ModalCentralized";

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  200: { items: 1 },
  350: { items: 1 },
  548: { items: 1 },
  720: { items: 2 },
  1024: { items: 2 },
};

const variants_responsive = {
  0: { items: 1 },
  200: { items: 2 },
  350: { items: 3 },
  548: { items: 4 },
  720: { items: 5 },
  1024: { items: 6 },
};

const animatedComponents = makeAnimated();

export default function ProductDetail(props: any) {
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());

  const [product_group_data, SetProductGroupData] = useState({});
  const [variant_data, SetVariantData] = useState([]);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [image_sizes, SetImageSizes] = useState([]);
  const [page_no, SetPageNo] = useState(1);
  const [sort_by, SetSortBy] = useState("desc");

  const [modal_centralized, SetModalCentralized] = useState({
    is_open: false,
    data: {},
  });

  const [modal_gallery, SetModalGallery] = useState({
    is_open: false,
    data: {},
    type: "select",
  });

  // local image
  const [img, SetImg] = useState([
    {
      src: require("../../assets/img/1.jpeg"),
    },
    {
      src: require("../../assets/img/2.jpeg"),
    },
    {
      src: require("../../assets/img/3.jpeg"),
    },
    {
      src: require("../../assets/img/4.jpeg"),
    },
  ]);
  const [addon_modal_data, SetAddonModalData] = useState({
    is_open: false,
    selected_addon: [],
    variant_index: "",
  });

  const [modal_addon, SetModalAddon] = useState({
    is_open: false,
    category: "",
    added_addon: [],
    group_spec_data: [],
  });

  const [modal_mrp, SetModalMrp] = useState({
    is_open: false,
    data: "",
    variant_index: "",
  });
  const [is_update_variant, SetIsUpdateVariant] = useState(false);

  const [modal_update_addon, SetModalUpdateAddon] = useState({
    is_open: false,
    addon_group_data: [],
    addon_index_data: [],
    specification_data: [],
  });

  const [modal_update_group_details, SetModalUpdateGroupDetails] = useState({
    is_open: false,
    group_name: "",
    group_detail: "",
  });

  const [show_update_request, SetShowUpdateRequest] = useState(false);
  // const [is_edit, SetIsEdit] = useState(false)
  const [is_edit, SetIsEdit] = useState(true);

  const [modal_delete_confirm, SetModalDeleteConfirm] = useState({
    is_open: false,
    data: {},
  });

  const [combination_rows, SetCombinationRows] = useState([]);
  useEffect(() => {
    console.log("ProductDetail On mount :", props);
    console.log("ProductDetail On mount context :", context);
    let pass_data = {
      page_no: page_no,
      sort_by: sort_by,
    };
    get_details(pass_data);
  }, []);

  async function get_details(pass_value: any) {
    let pass_data = {
      get: {
        product_id: id_value.product_group_id,
        page: pass_value.page_no,
        sort: pass_value.sort_by,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let response = await api("/product/product_variants", pass_data);
    console.log("/product/product_variants response :", response);
    if (response.status_code === 200) {
      if (response.response.hasOwnProperty("product_group")) {
        SetProductGroupData(response.response.product_group);
      }
      if (response.response.hasOwnProperty("image_sizes")) {
        SetImageSizes(response.response.image_sizes);
      }
      if (response.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(response.response.image_base_url);
      }
      if (response.response.hasOwnProperty("data")) {
        let v_data = response.response.data;
        v_data?.map((ele: any) => {
          ele.expand = false;
        });
        SetVariantData(v_data);
      }
      if (response.response.hasOwnProperty("combination_rows")) {
        SetCombinationRows(response.response.combination_rows);
      }
      if (response.response.next_page) {
        SetPageNo((prevValue: any) => {
          prevValue = prevValue + 1;
          return prevValue;
        });
      }
    }
  }

  function isArray(obj: any) {
    return Object.prototype.toString.call(obj) === "[object Array]";
  }

  async function remove_addon_group(pass_value: any) {
    let pass_data = {
      get: {},
      post: {
        addons: [pass_value.a_ele.id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("remove_variant_addon_group pass_data :", pass_data);
    // SetVariantData((prevValue: any) => {
    //   // prevValue[pass_value.v_index][pass_value.key][a_index];
    //   console.log(
    //     "data on click :",
    //     prevValue[pass_value.v_index][pass_value.key][pass_value.a_index]
    //   );

    //   return [...prevValue];
    // });
    let response = await api("/product/remove_variant_addon_group", pass_data);
    console.log("/product/remove_variant_addon_group response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Addon Group Removed");
        // let call_data = {
        //   page_no: page_no,
        //   sort_by: sort_by,
        // };
        // get_details(call_data);
        SetVariantData((prevValue: any) => {
          prevValue[pass_value.v_index][pass_value.key].splice(
            pass_value.a_index,
            1
          );
          console.log(
            "data on click :",
            prevValue[pass_value.v_index][pass_value.key]
          );

          return [...prevValue];
        });
      }
    }
  }

  async function remove_addon_item(pass_value: any) {
    let pass_data = {
      get: {},
      post: {
        addons: [pass_value.i_ele.id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("remove_variant_addon_items pass_data :", pass_data);
    let response = await api("/product/remove_variant_addon_items", pass_data);
    console.log("/product/remove_variant_addon_items response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Addon Item Removed");
        // let call_data = {
        //   page_no: page_no,
        //   sort_by: sort_by,
        // };
        // get_details(call_data);
        SetVariantData((prevValue: any) => {
          prevValue[pass_value.v_index][pass_value.key][
            pass_value.a_index
          ].items.splice(pass_value.i_index, 1);
          return [...prevValue];
        });
      }
    }
  }

  async function product_remove_addon_group(pass_value: any) {
    let pass_data = {
      get: {},
      post: {
        // addons: [pass_value.a_ele.id],
        addons: [pass_value.a_ele.product_addon_group_id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("remove_product_addon_group pass_data :", pass_data);
    let response = await api("/product/remove_product_addon_group", pass_data);
    console.log("/product/remove_product_addon_group response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Addon Group Removed");
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
      }
    }
  }

  async function product_remove_addon_item(pass_value: any) {
    let pass_data = {
      get: {},
      post: {
        addons: [pass_value.i_ele.id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("remove_product_addon_items pass_data :", pass_data);
    let response = await api("/product/remove_product_addon_items", pass_data);
    console.log("/product/remove_product_addon_items response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Addon Item Removed From Group");
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
        // SetVariantData((prevValue: any) => {
        //   prevValue[pass_value.v_index][pass_value.key][
        //     pass_value.a_index
        //   ].items.splice(pass_value.i_index, 1);
        //   return [...prevValue];
        // });
      }
    }
  }

  async function add_addon_group(data: any) {
    let pass_data = {
      get: {},
      post: {
        product_data: {
          id: id_value.product_group_id,
          addon_data: [data],
        },
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/add_variant_addons pass_data :", pass_data);
    let response = await api("/product/add_variant_addons", pass_data);
    console.log("/product/add_variant_addons response :", response);
    if (response.status_code == 201) {
      toast.success("Addon Group Added");
      let call_data = {
        page_no: page_no,
        sort_by: sort_by,
      };
      get_details(call_data);
    }
  }

  async function save_addon_to_variant(send_data: any) {
    console.log("save_addon_to_variant send_data:", send_data);
    let pass_data = {
      get: {},
      post: {
        product_data: send_data,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/add_variant_addons pass_data :", pass_data);
    let response = await api("/product/add_variant_addons", pass_data);
    console.log("/product/add_variant_addons response :", response);
    if (response.status_code == 201) {
      toast.success("Addon Group Added");
      let call_data = {
        page_no: page_no,
        sort_by: sort_by,
      };
      get_details(call_data);
    }
  }

  async function add_mrp(get_data: any) {
    let pass_data = {
      get: {},
      post: {
        variant_id: get_data.variant_data[get_data.variant_index].id,
        mrp: get_data.value,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/add_product_mrp pass_data :", pass_data);
    let response = await api("/product/add_product_mrp", pass_data);
    console.log("/product/add_product_mrp response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Mrp Added successfully");
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
      }
    }
  }

  async function remove_mrp(get_data: any) {
    let pass_data = {
      get: {},
      post: {
        mrp: [get_data],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/delete_product_mrp pass_data :", pass_data);
    let response = await api("/product/delete_product_mrp", pass_data);
    console.log("/product/delete_product_mrp response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Mrp removed successfully");
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
      }
    }
  }

  function get_selected_variant_value(child_value: any) {
    let return_data = [];
    child_value?.map((ele: any) => {
      if (ele.checked) {
        return_data.push(ele);
      }
    });
    return return_data;
  }

  async function update_variants_spectification() {
    let pass_data = {
      get: {},
      post: {
        product_id: id_value.product_group_id,
        specification: product_group_data.variants,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/add_variant_specification pass_data :", pass_data);
    let response = await api("/product/add_variant_specification", pass_data);
    console.log("/product/add_variant_specification response :", response);
    if (response.status_code == 200) {
      toast.success("Variant Spectification Updated");
      SetIsUpdateVariant(false);
      let call_data = {
        page_no: page_no,
        sort_by: sort_by,
      };
      get_details(call_data);
    }
  }

  async function remove_variant_image(get_data: any) {
    console.log("remove_variant_image get_data :", get_data);
    let pass_data = {
      get: {},
      post: {
        variant_id: get_data.variant.id,
        images: [get_data.image.id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/remove_variant_images pass_data :", pass_data);
    let response = await api("/product/remove_variant_images", pass_data);
    console.log("/product/remove_variant_images response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Variant Image Removed Successfully");
        let pass_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(pass_data);
      }
    }
  }

  async function remove_group_image(image_id: any) {
    console.log("remove_group_image image_id :", image_id);
    let pass_data = {
      get: {},
      post: {
        product_id: id_value.product_group_id,
        images: [image_id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/remove_product_images pass_data :", pass_data);
    let response = await api("/product/remove_product_images", pass_data);
    console.log("/product/remove_product_images response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Product Group Image Removed Successfully");
        let pass_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(pass_data);
      }
    }
  }

  async function add_group_image(get_data: any) {
    console.log("add_group_image get_data :", get_data);
    let pass_data = {
      get: {},
      post: {
        product_id: id_value.product_group_id,
        images: get_data.image,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/add_product_images pass_data :", pass_data);
    let response = await api("/product/add_product_images", pass_data);
    console.log("/product/add_product_images response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Product Group Image Added Successfully");
        let pass_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(pass_data);
      }
    }
  }

  async function add_variant_image(get_data: any) {
    console.log("add_variant_image get_data :", get_data);
    let pass_data = {
      get: {},
      post: {
        variant_id: get_data.variant_data.id,
        images: get_data.image,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/add_variant_images pass_data :", pass_data);
    let response = await api("/product/add_variant_images", pass_data);
    console.log("/product/add_variant_images response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Product Variant Image Added Successfully");
        let pass_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(pass_data);
      }
    }
  }

  function Badge({ label, bgColor = "#007BFF", textColor = "#FFFFFF" } : any) {
    const badgeStyle = {
      backgroundColor: bgColor,
      color: textColor,
      padding: "5px 10px",
      borderRadius: "20px",
      fontSize: "12px",
      fontWeight: "bold",
      display: "inline-block",
      width:"100%",
      textAlign:"center",
      margin:"2"
    };
  
    return <span style={badgeStyle} className="badge w-50 bg-success text-uppercase">{label}</span>;
  }

  return (
    <div className="">
      {/* Header */}
      <div className=" row align-items-center head-bar">
        <div className="d-flex align-items-center py-2">
          <div
            className="cursor"
            onClick={() => {
              window.history.back();
            }}
          >
            <Ionicons name="arrow-back-sharp" size={18} color="#222" />
          </div>
          <div className="col-md-4 col-7 ps-2 ">
            <h5 className=" mb-0">Group Details</h5>
          </div>
          {/* <div className="ms-auto">
            {!is_edit ? (
              <button className='btn btn-sm px-4 btn-primary'
                onClick={() => {
                  SetIsEdit(true)
                }}
              >
                Edit
              </button>
            ) : (
              <button className='btn btn-sm px-3 btn-secondary'
                onClick={() => {
                  SetIsEdit(false)
                }}
              >
                Cancel
              </button>
            )}
          </div> */}
        </div>
      </div>

      {/* Body */}
      <div className="container py-3 px-sm-0 px-md-3 mb-3">
        {/* <p>Group Details</p> */}
        <div className="bg-fff shadow mb-3 p-3">
          {/* basic */}
          <div className="row pb-3 border-bottom">
            <div className="col-md-5">
              {/* {Object.entries(product_group_data)?.map(([key, value]) =>
                product_group_data[key] != null ? (
                  isArray(product_group_data[key]) ? ( */}
              <div className="row">
                {/* <AliceCarousel
                  mouseTracking
                  touchMoveDefaultEvents={false}
                  // items={productsRestraunt}
                  // paddingLeft={50}
                  paddingRight={50}
                  disableDotsControls
                  responsive={responsive}
                  disableButtonsControls
                  controlsStrategy="alternate"
                >
                  {product_group_data.images?.map((img_ele: any, img_index) => (
                    <div
                      className=""
                      key={img_index}
                      onDragStart={handleDragStart}
                    >
                      <div className="px-1">
                        <img
                          src={
                            image_base_url + image_sizes[0] + '/' + img_ele.url
                          }
                          width="100%"
                          height="100%"
                          className="details_img "
                        />
                      </div>
                      {is_edit ? (
                        <div className="img_over_content">
                          <button
                            className="text-white mb-0 btn btn-sm d-flex align-items-center"
                            onClick={() => {
                              console.log('product group remove image')
                              remove_group_image(img_ele.id)
                            }}
                          >
                            <div className="pe-1">
                              <MaterialIcons
                                name="delete-outline"
                                size={16}
                                color="#fff"
                              />
                            </div>
                            Remove
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </AliceCarousel> */}

                {/* image */}
                <div className="swiper scrollX d-flex px-2 h-170">
                  {product_group_data.hasOwnProperty("images") ? (
                    product_group_data.images.length > 0 ? (
                      product_group_data.images.map(
                        (img_ele: any, img_index) => (
                          <div
                            className="col-md-6 col-12 px-1"
                            key={img_index}
                            onDragStart={handleDragStart}
                          >
                            <div className="px-1">
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  img_ele.url,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                // src={
                                //   image_base_url + image_sizes[0] + "/" + img_ele.url
                                // }
                                width="100%"
                                height="100%"
                                className="details_img "
                              />
                            </div>
                            {is_edit ? (
                              <div className="row_img_over_content">
                                <button
                                  className="text-white mb-0 btn btn-sm d-flex align-items-center"
                                  onClick={() => {
                                    console.log("product group remove image");
                                    // remove_group_image(img_ele.id)
                                    SetModalDeleteConfirm((prevValue: any) => {
                                      prevValue.is_open = true;
                                      prevValue.data = {
                                        title: "Delete Image",
                                        body: "Are you sure want to delete?",
                                        pass_value: img_ele.id,
                                        for: "image",
                                      };
                                      return { ...prevValue };
                                    });
                                  }}
                                >
                                  <div className="pe-1">
                                    <MaterialIcons
                                      name="delete-outline"
                                      size={16}
                                      color="#fff"
                                    />
                                  </div>
                                  Remove
                                </button>
                              </div>
                            ) : null}
                          </div>
                        )
                      )
                    ) : (
                      <>
                        <img
                          src={require("../../assets/img/no-image.jpg")}
                          className="details_img"
                        />
                      </>
                    )
                  ) : (
                    <>
                      <img
                        src={require("../../assets/img/no-image.jpg")}
                        className="details_img"
                      />
                    </>
                  )}
                </div>
                {/* edit button */}
                <div className="center mt-3 w-100">
                  {is_edit ? (
                    <button
                      className="btn w-75  shadow-btn bg-fff"
                      onClick={() => {
                        console.log("product group add image");
                        SetModalGallery((prevValue: any) => {
                          prevValue.is_open = true;
                          prevValue.type = "group";
                          return { ...prevValue };
                        });
                      }}
                    >
                      <span className="pe-1">
                        <MaterialCommunityIcons
                          name="image-multiple-outline"
                          size={16}
                          color="black"
                        />
                      </span>
                      Add Image
                    </button>
                  ) : null}
                  {!id_value.hasOwnProperty("brand_id") && (
                    product_group_data.centralized ? (
                      <button
                        className="btn w-75 m-2  shadow-btn bg-fff"
                        onClick={() => {
                          SetModalCentralized((prevValue: any) => {
                            prevValue.is_open = true;
                            prevValue.data = {
                              type: "uncentralized",
                              from: "product_group",
                            };
                            return { ...prevValue };
                          });
                        }}
                      >
                        Make Uncentralized
                      </button>
                    ) : (
                      <button
                        className="btn w-75 m-2 shadow-btn bg-fff"
                        onClick={() => {
                          SetModalCentralized((prevValue: any) => {
                            prevValue.is_open = true;
                            prevValue.data = {
                              type: "centralized",
                              from: "product_group",
                            };
                            return { ...prevValue };
                          });
                        }}
                      >
                        Make Centralized
                      </button>
                    )
                  )}

                </div>
              </div>
            </div>
            {/* group detail   */}
            <div className="col-md-7 pt-sm-2 pt-md-0">
              <div className="d-flex align-items-center pt-2">
                <h6 className="">{product_group_data.name}</h6>
                <div
                  className="end cursor"
                  onClick={() => {
                    SetModalUpdateGroupDetails((prevValue: any) => {
                      prevValue.is_open = true;
                      prevValue.data = product_group_data;
                      return { ...prevValue };
                    });
                  }}
                >
                  <Entypo name="edit" size={13} color="#0053ad" />
                  <p className="text-primary fw-bold v-small mb-0 ps-1">Edit</p>
                </div>
              </div>

              <p className="mb-3">
                <small className="fw-500 text-darkgray">
                  {product_group_data.description}
                </small>
              </p>

              <p className="mb-0 text-gray v-small pt-2">
                Brand :{" "}
                <b className="text-dark">{product_group_data.product_brand}</b>
              </p>
              <p className="mb-0 text-gray v-small pt-2">
                Category :{" "}
                <b className="text-dark">{product_group_data.category}</b>
              </p>
              <div className="d-flex align-items-center pt-2">
                <p className="text-gray v-small mb-0">
                  Status :
                  <b className="text-dark ps-1">{product_group_data.status}</b>
                </p>
                <div className="end">
                  <Entypo name="language" size={13} color="#777" />
                  <p className="text-gray v-small mb-0 ps-1">
                    {product_group_data.orgin_language}
                  </p>
                </div>
              </div>
              {product_group_data.centralized && (
                <div className="pt-2">
                  <Badge label="centralized" bgColor="#28A745" />
                </div>
              )}
            </div>
          </div>

          {/* on update notification */}
          <div className="px-2">
            {product_group_data.hasOwnProperty("update_request_basic") ? (
              Object.keys(product_group_data.update_request_basic).length >
              0 ? (
                <div className="p-2 bg-light mt-2 radius cursor">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      SetShowUpdateRequest(!show_update_request);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="lds-dual-ring"></div>
                      <div className="ps-2">
                        <p className="small text-gray mb-0">
                          Your updated details are under processing
                        </p>
                      </div>
                    </div>
                    <div className="ms-auto">
                      {show_update_request ? (
                        <MaterialIcons
                          name="keyboard-arrow-up"
                          size={20}
                          color="gray"
                        />
                      ) : (
                        <MaterialIcons
                          name="keyboard-arrow-down"
                          size={20}
                          color="gray"
                        />
                      )}
                    </div>
                  </div>
                  {show_update_request ? (
                    <div className="pb-2 pt-3">
                      <div className="d-flex">
                        <p className="text-dark fw-bold mb-2">
                          {product_group_data.update_request_basic.name}
                        </p>
                        <div className="ms-auto">
                          <p className="mb-2 text-black fw-500 v-extra-small">
                            {
                              product_group_data.update_request_basic
                                .updated_time
                            }
                          </p>
                        </div>
                      </div>
                      <p className="mb-0">
                        <small className="fw-500 text-light">
                          {product_group_data.update_request_basic.description}
                        </small>
                      </p>
                    </div>
                  ) : null}
                </div>
              ) : null
            ) : null}
          </div>

          {/* addon group / variant */}
          {Object.entries(product_group_data)?.map(([key, value]) =>
            product_group_data[key] != null ? (
              isArray(product_group_data[key]) ? (
                <div className="" key={key}>
                  {key == "addon_group" ? (
                    <div className="row pt-3 border-bottom pb-2">
                      <div className="d-flex align-items-center mb-3">
                        <h6 className="mb-0">Addon Groups Details</h6>
                        {is_edit ? (
                          <div
                            className="end cursor"
                            onClick={() => {
                              SetModalAddon((prevValue: any) => {
                                prevValue.is_open = true;
                                prevValue.added_addon = product_group_data[key];
                                let group_spec_data_value =
                                  product_group_data.variants;
                                group_spec_data_value?.map((ele: any) => {
                                  let value_data = [];
                                  ele.children?.map((c_ele: any) => {
                                    if (c_ele.checked) {
                                      c_ele.value = c_ele.specifications_id;
                                      c_ele.label = c_ele.name;

                                      value_data.push(c_ele);
                                    }
                                  });
                                  ele.value = value_data;
                                });
                                prevValue.group_spec_data =
                                  group_spec_data_value;

                                return { ...prevValue };
                              });
                            }}
                          >
                            <Entypo name="edit" size={13} color="#0053ad" />
                            <p className="text-primary v-small fw-bold mb-0 ps-1">
                              Add
                            </p>
                          </div>
                        ) : // <button
                        //   className="btn text-primary ms-auto btn-sm"
                        //   onClick={() => {
                        //     // from="product_details"
                        //     // added_addon={modal_addon.added_addon}
                        //     // group_spec_data={modal_addon.group_spec_data}
                        //     SetModalAddon((prevValue: any) => {
                        //       prevValue.is_open = true
                        //       prevValue.added_addon = product_group_data[key]
                        //       let group_spec_data_value =
                        //         product_group_data.variants
                        //       group_spec_data_value?.map((ele: any) => {
                        //         let value_data = []
                        //         ele.children?.map((c_ele: any) => {
                        //           if (c_ele.checked) {
                        //             c_ele.value = c_ele.specifications_id
                        //             c_ele.label = c_ele.name

                        //             value_data.push(c_ele)
                        //           }
                        //         })
                        //         ele.value = value_data
                        //       })
                        //       prevValue.group_spec_data = group_spec_data_value

                        //       return { ...prevValue }
                        //     })
                        //   }}
                        // >
                        //   Add Addon
                        // </button>
                        null}
                      </div>

                      {product_group_data[key].length != 0 ? (
                        product_group_data[key]?.map(
                          (a_ele: any, a_index: any) => (
                            <div className="col-md-6 mb-2" key={a_index}>
                              <div className="border radius p-2">
                                <div className="d-flex align-items-center bg">
                                  {/* <div className="col-1 p-0 center">
                              <p className=" text-black">{a_index+1}</p>
                              </div> */}
                                  <p className="text-dark mb-0">{a_ele.name}</p>
                                  {is_edit ? (
                                    <div className="ms-auto">
                                      {/* remove */}
                                      <button
                                        className="btn btn-sm"
                                        onClick={() => {
                                          let pass_value = {
                                            a_ele: a_ele,
                                            a_index: a_index,
                                            key: key,
                                            // v_ele: v_ele,
                                            // v_index: v_index,
                                          };
                                          // product_remove_addon_group(pass_value)
                                          console.log(
                                            "pass_value :",
                                            pass_value
                                          );
                                          SetModalDeleteConfirm(
                                            (prevValue: any) => {
                                              prevValue.is_open = true;
                                              prevValue.data = {
                                                title: "Delete Addon group",
                                                body: "Are you sure want to delete?",
                                                pass_value: pass_value,
                                                for: "addon_group",
                                              };
                                              return { ...prevValue };
                                            }
                                          );
                                        }}
                                      >
                                        <MaterialIcons
                                          name="delete-outline"
                                          size={16}
                                          color="gray"
                                        />
                                        {/* <AntDesign name="delete" size={18} color="gray" /> */}
                                        {/* Remove */}
                                      </button>
                                      {/* edit */}
                                      <button
                                        className="btn btn-sm ms-2"
                                        onClick={() => {
                                          SetModalUpdateAddon(
                                            (prevValue: any) => {
                                              prevValue.is_open = true;
                                              prevValue.addon_group_data =
                                                product_group_data[key];
                                              prevValue.addon_index_data =
                                                a_index;
                                              prevValue.specification_data =
                                                product_group_data.hasOwnProperty(
                                                  "variants"
                                                )
                                                  ? product_group_data.variants
                                                  : [];
                                              return { ...prevValue };
                                            }
                                          );
                                        }}
                                      >
                                        <AntDesign
                                          name="edit"
                                          size={16}
                                          color="gray"
                                        />
                                        {/* Update Addon */}
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                                {a_ele.hasOwnProperty("specification_id") ? (
                                  <p className="mb-0">
                                    <small className="text-darkgray fw-500">
                                      Dependent With {a_ele.specification}
                                    </small>
                                  </p>
                                ) : (
                                  <p className="mb-0">
                                    <small className="text-darkgray fw-500">
                                      InDependent
                                    </small>
                                  </p>
                                )}
                                <p className="mb-0">
                                  <small className="text-light fw-500">
                                    {a_ele.notes}
                                  </small>
                                </p>
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <div className="text-center p-3">
                          <p>No Data Found!</p>
                        </div>
                      )}
                    </div>
                  ) : null}

                  {key == "variants" ? (
                    <div className="pt-3">
                      <div className="d-flex align-items-center mb-3">
                        <h6 className="mb-0">Variants </h6>
                        {is_edit ? (
                          <div className="ms-auto">
                            {!is_update_variant ? (
                              <button
                                className="btn btn-sm text-primary"
                                onClick={() => {
                                  SetIsUpdateVariant(true);
                                  SetProductGroupData((prevValue: any) => {
                                    prevValue[key]?.map((ele: any) => {
                                      ele.label = ele.name;
                                      ele.value = ele.specifications_id;
                                      ele.children?.map((child: any) => {
                                        child.label = child.name;
                                        child.value = child.specifications_id;
                                      });
                                    });
                                    return { ...prevValue };
                                  });
                                }}
                              >
                                Update Variants
                              </button>
                            ) : (
                              <div className="d-flex align-items-center">
                                <button
                                  className="btn btn-sm me-2 text-success text-center "
                                  onClick={() => {
                                    update_variants_spectification();
                                  }}
                                >
                                  <MaterialIcons
                                    name="file-download-done"
                                    size={16}
                                    color="green"
                                  />
                                  <span className="text-green ps-1">Save</span>
                                </button>
                                <button
                                  className="btn btn-sm  text-black"
                                  onClick={() => {
                                    SetIsUpdateVariant(false);
                                  }}
                                >
                                  <Ionicons
                                    name="ios-close"
                                    size={16}
                                    color="#777"
                                  />
                                  <span className="text-gray ps-1">Cancel</span>
                                </button>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div className="row px-3">
                        {!is_update_variant ? (
                          product_group_data[key]?.map(
                            (a_ele: any, a_index: any) => (
                              <div className="col-md-3 pb-3 px-2" key={a_index}>
                                <div className="card p-2">
                                  <p className="fw-bold text-dark mb-2 p-1 pb-0">
                                    {a_ele.name}
                                  </p>
                                  <div className="px-2 ">
                                    {a_ele.children?.map(
                                      (c_ele: any, c_index: any) =>
                                        c_ele.checked ? (
                                          <p
                                            key={c_index}
                                            className="v-small text-darkgray mb-2"
                                          >
                                            <Entypo
                                              name="dot-single"
                                              size={16}
                                              color="black"
                                            />
                                            {c_ele.name}
                                          </p>
                                        ) : null
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div className="row">
                            {product_group_data[key]?.map(
                              (a_ele: any, a_index: any) => (
                                <div className="col-md-6 mb-2">
                                  <label className="form-label">
                                    {a_ele.name}
                                  </label>
                                  <Select
                                    components={animatedComponents}
                                    placeholder="Select Country"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable={false}
                                    isMulti
                                    options={a_ele.children}
                                    value={get_selected_variant_value(
                                      a_ele.children
                                    )}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    onChange={(e, action) => {
                                      console.log(" Country :", e);
                                      console.log(" action :", action);

                                      if (action.action == "remove-value") {
                                        SetProductGroupData(
                                          (prevValue: any) => {
                                            prevValue[key][
                                              a_index
                                            ].children?.map((child: any) => {
                                              if (
                                                child.specifications_id ==
                                                action.removedValue
                                                  .specifications_id
                                              ) {
                                                if (child.strict) {
                                                  toast.error("Cannot Remove");
                                                } else {
                                                  child.checked = false;
                                                }
                                              }
                                            });
                                            return { ...prevValue };
                                          }
                                        );
                                      }
                                      if (action.action == "select-option") {
                                        SetProductGroupData(
                                          (prevValue: any) => {
                                            prevValue[key][
                                              a_index
                                            ].children?.map((child: any) => {
                                              if (
                                                child.specifications_id ==
                                                action.option.specifications_id
                                              ) {
                                                child.checked = true;
                                              }
                                            });
                                            return { ...prevValue };
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null
            ) : null
          )}
        </div>
        {/* <p>Variants Details</p> */}
        <div className="mb-3">
          <div className="card mb-1">
            <div className="d-flex align-items-center ">
              <h6 className="mb-0 p-3">Variants Details</h6>

              {combination_rows.length > 0 ? (
                is_edit ? (
                  <Link
                    className="end p-3"
                    to={
                      // context.app_data.app_from == 'seller'
                      id_value.hasOwnProperty("brand_id")
                        ? "/seller/" +
                          id_value.brand_id +
                          "/group/edit_variant/" +
                          id_value.product_group_id
                        : "/manufacturer/" +
                          id_value.m_id +
                          "/group/edit_variant/" +
                          id_value.product_group_id
                    }
                  >
                    <AntDesign name="edit" size={16} color="#0053ad" />
                    <p className="text-primary fw-bold mb-0 ps-2">Edit</p>
                  </Link>
                ) : null
              ) : null}
            </div>
          </div>

          {variant_data?.map((v_ele: any, v_index: any) => (
            <div className="card mb-2" key={v_index}>
              <div className="d-flex scrollX p-3 pb-0">
                {v_ele.group_variant_specification?.map((g_ele, g_index) => (
                  <div className="p-2 border-right" key={g_index}>
                    <p className="mb-1 pe-2 text-gray extra-small">
                      {g_ele.parent_name}
                    </p>
                    <p className="mb-0 pe-2 text-darkgray fw-bold v-small">
                      {g_ele.name}
                    </p>
                  </div>
                ))}
                <div className="ms-auto">
                  <Link
                    to={
                      // context.app_data.app_from == 'seller'
                      id_value.hasOwnProperty("brand_id")
                        ? "/seller/" +
                          id_value.brand_id +
                          "/product/details/" +
                          v_ele.id
                        : "/manufacturer/" +
                          id_value.m_id +
                          "/product/details/" +
                          v_ele.id
                    }
                  >
                    <button className="btn btn-primary btn-radius btn-sm px-3">
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
              <div
                className="p-3 cursor"
                onClick={() => {
                  SetVariantData((prevValue: any) => {
                    prevValue[v_index].expand = !prevValue[v_index].expand;
                    return [...prevValue];
                  });
                }}
              >
                <div className="d-flex">
                  <div className="d-flex">
                    <img
                      src={
                        v_ele.hasOwnProperty("images")
                          ? AppHelper.imageSrc(
                              image_base_url,
                              v_ele.images[0].url,
                              {
                                format: "webp",
                                width: 320,
                              }
                            )
                          : require("../../assets/img/no-image.jpg")
                      }
                      // src={
                      //   v_ele.hasOwnProperty("images")
                      //     ? image_base_url +
                      //       image_sizes[0] +
                      //       "/" +
                      //       v_ele.images[0].url
                      //     : null
                      // }
                      className="variant_img"
                    />
                    <div className="ps-2">
                      <p className="mb-1 fw-bold text-dark">{v_ele.name}</p>
                      <p className="mb-0">
                        <small className="text-light extra-small fw-500">
                          {v_ele.description}
                        </small>
                      </p>
                    </div>
                  </div>
                  {/* <div className="row mt-2">
                        {v_ele.group_variant_specification?.map(
                          (g_ele, g_index) => (
                            <div className="col-3 col-md-2" key={g_index}>
                              <div className="pt-1 ">
                                <p className="mb-1 pe-2 text-gray extra-small">
                                  {g_ele.parent_name}
                                </p>
                                <p className="mb-0 pe-2 text-darkgray fw-bold v-small">
                                  {g_ele.name}
                                </p>
                              </div>
                            </div>
                          ),
                        )}
                      </div> */}

                  <div className="ms-auto">
                    {v_ele.expand == true ? (
                      <MaterialIcons
                        name="keyboard-arrow-down"
                        size={16}
                        color="#444"
                      />
                    ) : (
                      <MaterialIcons
                        name="keyboard-arrow-up"
                        size={16}
                        color="#444"
                      />
                    )}
                  </div>
                </div>
                {/* <p>Description : {v_ele.description}</p> */}
              </div>

              {v_ele.expand ? (
                <div className="p-2">
                  {/* Images */}
                  <div className="pb-3">
                    <div className="d-flex align-items-center mb-2">
                      <h6 className="text-dark p-2 pt-3 mb-0">Images</h6>
                      {/* {is_edit ? (
                        <div className="ms-auto">
                          <button
                            className="btn btn-sm text-primary"
                            onClick={() => {
                              console.log('Add Image')
                              SetModalGallery((prevValue: any) => {
                                prevValue.is_open = true
                                prevValue.type = 'variant'
                                prevValue.data = v_ele
                                return { ...prevValue }
                              })
                            }}
                          >
                            Add Image
                          </button>
                        </div>
                      ) : null} */}
                    </div>

                    {v_ele.images ? (
                      <div className="scrollX d-flex px-2 h-170">
                        {v_ele.images?.map((img_ele: any, img_index) => (
                          <div
                            className="col-md-3 col-lg-2 col-6 px-1"
                            key={img_index}
                          >
                            <div className="">
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  img_ele.url,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                // src={
                                //   image_base_url +
                                //   image_sizes[0] +
                                //   "/" +
                                //   img_ele.url
                                // }
                                alt=""
                                width="100%"
                                height="100%"
                                className="details_img"
                              />
                            </div>
                            {/* {is_edit ? (
                              <div className="row_img_over_content">
                                <button
                                  className="text-white mb-0 btn btn-sm d-flex align-items-center"
                                  onClick={() => {
                                    console.log('Remove images')
                                    let send_data = {
                                      variant: v_ele,
                                      image: img_ele,
                                    }
                                    remove_variant_image(send_data)
                                  }}
                                >
                                  <div className="pe-1">
                                    <MaterialIcons
                                      name="delete-outline"
                                      size={16}
                                      color="#fff"
                                    />
                                  </div>
                                  Remove
                                </button>
                              </div>
                            ) : null} */}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>

                  {/* Specifications Details */}
                  <div className="pb-2">
                    <h6 className="text-dark p-2">Specifications Details</h6>
                    <div className="px-2">
                      {v_ele.description != "" && v_ele.description != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">Description</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.description}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.orgin_country != "" &&
                      v_ele.orgin_country != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">
                              Country of Orgin
                            </p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2">
                              {v_ele.orgin_country}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.product_brand != "" &&
                      v_ele.product_brand != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">Brand</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2">
                              {v_ele.product_brand}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.hsn != "" && v_ele.hsn != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">HSN</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.hsn}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.gst != "" && v_ele.gst != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">GST</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.gst}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.gtin != "" && v_ele.gtin != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">GTIN</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.gtin}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.upc != "" && v_ele.upc != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">UPC</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.upc}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.ean != "" && v_ele.ean != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">EAN</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.ean}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.jan != "" && v_ele.jan != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">JAN</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.jan}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.isbn != "" && v_ele.isbn != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">ISBN</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.isbn}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.mpn != "" && v_ele.mpn != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">MPN</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.mpn}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.min_handling_time != "" &&
                      v_ele.min_handling_time != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">
                              Min Handling Time
                            </p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.min_handling_time}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.max_handling_time != "" &&
                      v_ele.max_handling_time != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">
                              Max Handling Time
                            </p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.max_handling_time}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {(v_ele.length != "" && v_ele.length != null) ||
                      (v_ele.width != "" && v_ele.width != null) ||
                      (v_ele.height != "" && v_ele.height != null) ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">
                              Length / Width / Height
                            </p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2 v-small">
                              {v_ele.length + " " + v_ele.length_unit} /{" "}
                              {v_ele.width + " " + v_ele.width_unit} /{" "}
                              {v_ele.height + " " + v_ele.height_unit}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.weight != "" && v_ele.weight != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">Weight</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2">
                              {v_ele.weight + " " + v_ele.weight_unit_name}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.package_length != "" &&
                      v_ele.package_length != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">Package Length</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2">
                              {v_ele.package_length +
                                " " +
                                v_ele.package_length_unit_name}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.package_breadth != "" &&
                      v_ele.package_breadth != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">
                              Package Breadth
                            </p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2">
                              {v_ele.package_breadth +
                                " " +
                                v_ele.package_breadth_unit_name}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.package_height != "" &&
                      v_ele.package_height != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">Package Height</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2">
                              {v_ele.package_height +
                                " " +
                                v_ele.package_height_unit_name}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.package_weight != "" &&
                      v_ele.package_weight != null ? (
                        <div className="row p-0 border-top">
                          <div className="col-5 bg-light p-0 px-2 border-right">
                            <p className="mb-0 text-dark p-2">Package Weight</p>
                          </div>
                          <div className="col-7 p-0">
                            <p className="text-gray mb-0 p-2">
                              {v_ele.package_weight +
                                " " +
                                v_ele.package_weight_unit_name}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {v_ele.specifications?.map(
                        (spec_ele: any, spec_index: any) => (
                          <div className="row p-0 border-top">
                            <div className="col-5 bg-light p-0 px-2 border-right">
                              <p className="mb-0 text-dark p-2">
                                {spec_ele.name}
                              </p>
                            </div>
                            <div className="col-7 p-0">
                              <p
                                className="text-gray mb-0 p-2 v-small"
                                onClick={() => {
                                  console.log("spec_ele :", spec_ele);
                                }}
                              >
                                {spec_ele.hasOwnProperty("value")
                                  ? Array.isArray(spec_ele.value)
                                    ? spec_ele.value.map(
                                        (v_ele: any) => v_ele.name + ","
                                      )
                                    : spec_ele.value
                                  : null}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  {/* MRP */}
                  <div className="table-responsive mt-2">
                    <div className="d-flex align-items-center mb-2">
                      <h6 className="text-dark p-2 pt-3 mb-0">MRP Details</h6>
                      {/* {is_edit ? (
                        <div className="ms-auto">
                          <button
                            className="btn btn-sm text-primary"
                            onClick={() => {
                              SetModalMrp((prevValue: any) => {
                                prevValue.is_open = true
                                prevValue.variant_index = v_index
                                return { ...prevValue }
                              })
                            }}
                          >
                            Add Mrp
                          </button>
                        </div>
                      ) : null} */}
                    </div>
                    {v_ele.hasOwnProperty("mrp") ? (
                      <table className="table teble-hover pad-table">
                        <thead className="bg-light p-2 border-bottom">
                          {/* <th>#</th> */}
                          <th>Country</th>
                          <th>State</th>
                          <th>Currency</th>
                          <th>Mrp</th>
                          {/* <th>Action</th> */}
                        </thead>
                        <tbody>
                          {v_ele.mrp?.map((mrp_ele, mrp_index) => (
                            <tr key={mrp_index}>
                              {/* <td>{mrp_index + 1}</td> */}
                              <td>{mrp_ele.country_name}</td>
                              <td>{mrp_ele.state_name}</td>
                              <td>
                                {mrp_ele.currency} - {mrp_ele.currency_name}
                              </td>
                              <td>{mrp_ele.mrp}</td>
                              {/* <td>
                                <button
                                  className="btn btn-sm"
                                  onClick={() => {
                                    remove_mrp(mrp_ele.id)
                                  }}
                                >
                                  <MaterialIcons
                                    name="delete-outline"
                                    size={16}
                                    color="gray"
                                  />
                                </button>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No Mrp Details</p>
                    )}
                  </div>

                  {/* Addon Groups Details */}
                  <div className="">
                    <div className="d-flex align-items-center pb-2 pt-3">
                      <h6 className="text-dark p-2">Addon Groups Details</h6>
                      {is_edit ? (
                        <div
                          className="end p-2 cursor"
                          onClick={() => {
                            SetAddonModalData((prevValue: any) => {
                              prevValue.is_open = !prevValue.is_open;
                              prevValue.selected_addon = v_ele.addon_group;
                              prevValue.variant_index = v_index;
                              return { ...prevValue };
                            });
                          }}
                        >
                          <p className="text-primary fw-bold mb-0 pe-2">
                            Choose
                          </p>
                          <Entypo
                            name="select-arrows"
                            size={14}
                            color="#0053ad"
                          />
                        </div>
                      ) : null}
                    </div>
                    {v_ele.addon_group.length > 0 ? (
                      <div className="row">
                        {v_ele.addon_group?.map((addon_ele, addon_index) => (
                          <div
                            className="col-lg-4 col-md-6 mb-2"
                            key={addon_index}
                          >
                            <div className="p-2 card">
                              <div className="d-flex">
                                <div>
                                  <p className="text-darkgray mb-0">
                                    {addon_ele.name}
                                  </p>
                                  <small className="text-light">
                                    {addon_ele.notes}
                                  </small>
                                </div>
                                {is_edit ? (
                                  <div className="ms-auto">
                                    <button
                                      className="btn btn-sm btn-light border-none bg-fff"
                                      onClick={() => {
                                        let pass_value = {
                                          a_ele: addon_ele,
                                          a_index: addon_index,
                                          key: "addon_group",
                                          v_ele: v_ele,
                                          v_index: v_index,
                                        };
                                        // remove_addon_group(pass_value)
                                        SetModalDeleteConfirm(
                                          (prevValue: any) => {
                                            prevValue.is_open = true;
                                            prevValue.data = {
                                              title: "Remove Addon Group",
                                              body: "Are you sure want to remove?",
                                              pass_value: pass_value,
                                              for: "variant_addon_group",
                                            };
                                            return { ...prevValue };
                                          }
                                        );
                                      }}
                                    >
                                      <Ionicons
                                        name="md-close"
                                        size={16}
                                        color="black"
                                      />
                                    </button>
                                  </div>
                                ) : null}
                              </div>

                              {addon_ele.items?.map((item_ele, item_index) => (
                                <div className="py-1 px-2" key={item_index}>
                                  <div className="d-flex align-items-center">
                                    <p className="v-small text-black  mb-0">
                                      {item_ele.name}
                                    </p>
                                    {is_edit ? (
                                      <div className="ms-auto">
                                        <button
                                          className="btn btn-sm btn-light border-none bg-fff"
                                          onClick={() => {
                                            let pass_value = {
                                              a_ele: addon_ele,
                                              a_index: addon_index,
                                              key: "addon_group",
                                              v_ele: v_ele,
                                              v_index: v_index,
                                              i_ele: item_ele,
                                              i_index: item_index,
                                            };
                                            console.log(
                                              "variant_data :",
                                              variant_data
                                            );
                                            console.log(
                                              "pass_value :",
                                              pass_value
                                            );

                                            // SetVariantData((prevValue: any) => {
                                            //   prevValue[pass_value.v_index][pass_value.key][
                                            //     pass_value.a_index
                                            //   ].items.splice(pass_value.i_index, 1)
                                            //   return [...prevValue]
                                            // })

                                            // remove_addon_item(pass_value)

                                            SetModalDeleteConfirm(
                                              (prevValue: any) => {
                                                prevValue.is_open = true;
                                                prevValue.data = {
                                                  title: "Remove Addon Item",
                                                  body: "Are you sure want to remove?",
                                                  pass_value: pass_value,
                                                  for: "variant_addon_group_item",
                                                };
                                                return { ...prevValue };
                                              }
                                            );
                                          }}
                                        >
                                          <MaterialIcons
                                            name="delete-outline"
                                            size={18}
                                            color="gray"
                                          />
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>

      <ModalDeleteConfirm
        open={modal_delete_confirm.is_open}
        data={modal_delete_confirm.data}
        close={(data: any) => {
          SetModalDeleteConfirm((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = {};
            return { ...prevValue };
          });
          if (data.action == "save") {
            if (data.for == "image") {
              remove_group_image(data.pass_value);
            }
            if (data.for == "addon_group") {
              product_remove_addon_group(data.pass_value);
            }
            if (data.for == "variant_addon_group_item") {
              remove_addon_item(data.pass_value);
            }

            if (data.for == "variant_addon_group") {
              remove_addon_group(data.pass_value);
            }
          }
        }}
      />

      <AddonModal
        open={addon_modal_data.is_open}
        from={"product_details"}
        selected_addon={addon_modal_data.selected_addon}
        product_data={product_group_data}
        variant_data={variant_data[addon_modal_data.variant_index]}
        close={(data) => {
          console.log("On close addon modal data: ", data);
          SetAddonModalData((prevValue: any) => {
            prevValue.is_open = !prevValue.is_open;
            return { ...prevValue };
          });
          if (data.action == "save") {
            console.log(
              "addon_modal_on_submit checked_value:",
              data.checked_value
            );
            console.log(
              "addon_modal_on_submit product_data :",
              data.product_data.addon_group
            );
            let checked_value = data.checked_value;
            let addon_group = data.product_data.addon_group;
            addon_group?.map((ele: any) => {
              ele.children?.map((c_ele: any, c_index: any) => {
                if (
                  checked_value.some(
                    (check_ele) => check_ele == c_ele.addon_group_item_id
                  )
                ) {
                  ele.checked = true;
                  c_ele.checked = true;
                } else {
                  c_ele.checked = false;
                }
              });
            });
            console.log("addon_group :", addon_group);
            let send_value = {
              id: data.product_data.id,
              combination_data: [
                {
                  id: data.variant_data.id,
                  addon: [],
                },
              ],
            };

            addon_group?.map((g_ele: any) => {
              let push_value = {
                value: "",
                children: [],
              };
              if (g_ele.checked) {
                push_value.value = g_ele.id;
                g_ele.children?.map((c_ele: any) => {
                  if (c_ele.checked) {
                    push_value.children.push({
                      value: c_ele.addon_group_item_id,
                    });
                  }
                });
                send_value.combination_data[0].addon.push(push_value);
              }
            });
            console.log("send_value :", send_value);
            save_addon_to_variant(send_value);
          }
        }}
      />

      <ModalCreateAddonGroup
        open={modal_addon.is_open}
        type={"add"}
        data={""}
        addon_data={[]}
        category_id={product_group_data.category_id}
        from="product_details"
        added_addon={modal_addon.added_addon}
        group_spec_data={modal_addon.group_spec_data}
        close={(data: any) => {
          console.log("ModalCreateAddonGroup on close :", data);
          SetModalAddon((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // get_addon_items();
            // SetAddonDetails((prevValue: any) => {
            //   prevValue.addon_data.push(data.data);
            //   return { ...prevValue };
            // });
            add_addon_group(data.data);
          }
        }}
      />

      <ModalMrp
        open={modal_mrp.is_open}
        variant_index={modal_mrp.variant_index}
        variant_data={variant_data}
        close={(data: any) => {
          console.log("ModalMrp on close data : ", data);
          // console.log('variant_data :', data.variant_data[data.variant_index])
          SetModalMrp((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            add_mrp(data);
          }
        }}
      />

      <UpdateAddonModal
        open={modal_update_addon.is_open}
        addon_group_data={modal_update_addon.addon_group_data}
        addon_index_data={modal_update_addon.addon_index_data}
        specification_data={modal_update_addon.specification_data}
        close={(data: any) => {
          console.log("UpdateAddonModal on close data : ", data);
          SetModalUpdateAddon((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            toast.success("Addon Dependencies Updated");
            let pass_data = {
              page_no: page_no,
              sort_by: sort_by,
            };
            get_details(pass_data);
            // add_mrp(data)
          }
        }}
      />

      <UpdateGroupDetailModal
        open={modal_update_group_details.is_open}
        data={modal_update_group_details.data}
        close={(data) => {
          SetModalUpdateGroupDetails((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            toast.success("Product Group Details Updated");
            let pass_data = {
              page_no: page_no,
              sort_by: sort_by,
            };
            get_details(pass_data);
          }
        }}
      />

      <ModalAddGallery
        open={modal_gallery.is_open}
        type={modal_gallery.type}
        data={modal_gallery.data}
        from={"group_detail"}
        close={(data: any) => {
          console.log("ModalAddGallery on close :", data);
          SetModalGallery((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            let send_data = {
              image: [],
            };
            data.value?.map((ele: any) => {
              send_data.image.push(ele.id);
            });
            if (data.type == "variant") {
              send_data.variant_data = data.variant_data;
              add_variant_image(send_data);
            } else {
              add_group_image(send_data);
            }
          }
        }}
      />

      <ModalCentralized 
        open = {modal_centralized.is_open}
        data = {modal_centralized.data}
        close={(data : any)=>{
          SetModalCentralized((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if(data.action == "save"){
            let pass_data = {
              page_no: page_no,
              sort_by: sort_by,
            };
            get_details(pass_data);
          }
        }}
      />

    </div>
  );
}
